import clsx from "clsx";
import { motion } from "framer-motion";

import { useMobileApp } from "@chef/feature-mobile-app";
import { useScrollDirection } from "@chef/hooks";

import { DefaultVariant } from "./DefaultVariant";
import { StepsVariant } from "./StepsVariant";
import { SimpleVariant } from "./SimpleVariant";
import { NavigationProgress } from "./NavigationProgress";
import type { Step } from "./types";
import { B2BVariant } from "./B2BVariant";
import { SignupVariant } from "./SignupVariant";

type DefaultVariantProps = {
  variant?: "default";
};

type SimpleVariantProps = {
  variant: "simple";
} & (
  | ({ exitLabel: string } & (
      | {
          onClose: () => void;
        }
      | {
          onBack: () => void;
        }
      | {
          closeHref: string;
        }
      | {
          backHref: string;
        }
    ))
  | { exitLabel?: never }
);

type B2BVariantProps = {
  variant: "b2b";
};

type SignupVariantProps = {
  variant: "signup";
  current: number;
  steps: Step[];
};

type BaseStepsVariantProps = {
  variant: "steps";
  notSticky?: boolean;
} & (
  | {
      onClose: () => void;
      exitLabel: string;
    }
  | {
      onBack: () => void;
      exitLabel: string;
    }
  | {
      backHref: string;
      exitLabel: string;
    }
  | {
      closeHref: string;
      exitLabel: string;
    }
  | {
      exitLabel?: never;
    }
) &
  (
    | {
        onCartClick: () => void;
        cartCount: number;
      }
    | { onCartClick?: never; cartCount?: never }
  );

type StepsVariantWithSteps = BaseStepsVariantProps & {
  current: number;
  steps: Step[];
  title?: never;
};

type StepsVariantWithStep = BaseStepsVariantProps & {
  current?: never;
  title: string;
  steps?: never;
};

type StepsVariantProps = StepsVariantWithSteps | StepsVariantWithStep;

type HeaderProps = {
  printHidden?: boolean;
  title?: string;
} & (
  | DefaultVariantProps
  | StepsVariantProps
  | SimpleVariantProps
  | B2BVariantProps
  | SignupVariantProps
);
const isStepsVariant = (props: HeaderProps): props is StepsVariantProps => {
  return props.variant === "steps";
};

const isDefaultVariant = (props: HeaderProps): props is DefaultVariantProps => {
  return props.variant === "default" || !props.variant;
};

const isSimpleVariant = (props: HeaderProps): props is SimpleVariantProps => {
  return props.variant === "simple";
};

const isB2BVariant = (props: HeaderProps): props is B2BVariantProps => {
  return props.variant === "b2b";
};

const isSignupVariant = (props: HeaderProps): props is SignupVariantProps => {
  return props.variant === "signup";
};

export const Header = (props: HeaderProps) => {
  const { isOldApp } = useMobileApp();
  const { direction } = useScrollDirection(60);

  const isDefault = isDefaultVariant(props);
  const isSteps = isStepsVariant(props);
  const isSimple = isSimpleVariant(props);
  const isB2B = isB2BVariant(props);
  const isSignup = isSignupVariant(props);

  const sticky = isSteps && direction !== "down";

  return (
    <>
      <NavigationProgress />

      <motion.header
        className={clsx(
          "bg-background page-padding",
          sticky && "shadow-fab",
          isSteps && !props.notSticky && "sticky z-20 top-0",
          props.printHidden && "print:hidden",
          isOldApp && "pt-12",
        )}
        {...(isSteps
          ? {
              animate: {
                y: sticky ? 0 : -100,
              },
              transition: { ease: "easeInOut", duration: 0.2 },
            }
          : {})}
      >
        <div className={clsx(!isSignup && "page-max-width")}>
          {isDefault && <DefaultVariant />}
          {isSteps && <StepsVariant {...props} />}
          {isSimple && <SimpleVariant {...props} />}
          {isB2B && <B2BVariant />}
          {isSignup && <SignupVariant {...props} />}
        </div>
      </motion.header>
    </>
  );
};
