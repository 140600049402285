import { getNextCutoff } from "@chef/helpers";
import { toØre } from "@chef/utils/currency";
import { sumByFn } from "@chef/utils/array";
import { config } from "@chef/constants";

import { useCreateBillingSessionMutation } from "../features";
import { useBillingQuery, useUserInfoQuery } from "../graphql/generated";
import { useCalendarData } from "./useCalendarData";
import {
  getBasketForCalendarWeek,
  getNextDeliveryFromCalendar,
  getPriceOfProductByVariation,
} from "../helpers";

export const usePaymentSession = () => {
  const { week, year } = getNextCutoff();

  const { data: userInfoQuery, isLoading: isLoadingUserInfo } =
    useUserInfoQuery();

  const { calendar, isLoading: isLoadingCalendar } = useCalendarData({
    week,
    year,
  });

  const [createBillingSession, { isLoading: isCreatingBillingSession }] =
    useCreateBillingSessionMutation();

  const { data: billingQuery, isLoading: isLoadingBilling } = useBillingQuery(
    undefined,
    { refetchOnFocus: true },
  );

  const createSession = async () => {
    if (!userInfoQuery || !billingQuery || !calendar) {
      return;
    }

    const { email, firstName, lastName, telephone } = userInfoQuery.userInfo;

    const url = new URL("/api/billing/callback", window.location.origin);
    url.searchParams.set("d", window.location.pathname);

    let total = 0;

    const calendarWeek =
      getNextDeliveryFromCalendar({ calendar }) || calendar[0];

    const basket = getBasketForCalendarWeek({ calendarWeek });

    const preselectorBasket = calendar.find(
      (c) => c.week === week && c.year === year,
    )?.preselectorBasket;

    if (!basket) {
      console.error("No basket found");
      return;
    }

    const basketItems = basket.basketDetails.products.map((p) => {
      const isPreselected = preselectorBasket?.variationIds.includes(
        p.variationId,
      );

      return {
        productId: p.variation.productId,
        productTypeId: p.variation.product.productTypeId,
        variationId: p.variationId,
        name: p.variation.name,
        quantity: p.quantity,
        price: isPreselected
          ? 0
          : toØre(getPriceOfProductByVariation(p.variation)),
      };
    });

    const sumOfItems = sumByFn(
      basketItems,
      (item) => item.price * item.quantity,
    );

    const deliveryFee = toØre(
      basket.shippingDetails.plannedDeliveryInformation.customerFee,
    );

    total += sumOfItems + deliveryFee;

    const net = total;
    const vat = Math.round(net * config.vatRate);

    const { redirectUri } = await createBillingSession({
      payment: {
        redirectUri: url.toString(),
      },
      subject: {
        email,
        firstName,
        lastName,
        nin: null,
        telephone,
      },
      basket: {
        items: basketItems,
        _meta: {
          deliveryFee,
          total,

          credit: 0, // Setting to 0 as it is difficult to predict
          discount: 0, // Setting to 0 as it is difficult to predict

          net,
          vat,

          week: basket.week,
          year: basket.year,
        },
      },
      cancellationUrl: window.location.href,
    }).unwrap();

    if (redirectUri) {
      window.location.href = redirectUri;
    }
  };

  return {
    createSession,
    isCreating: isCreatingBillingSession,
    isLoading: isLoadingUserInfo || isLoadingBilling || isLoadingCalendar,
  };
};
