import { useEffect, useState } from "react";
import { useTrack } from "@chef/feature-tracking";

import {
  useFavoriteRecipeMutation,
  useMeQuery,
  useUnfavoriteRecipeMutation,
} from "../features";
import { useRecipesAgreementFavoritesQuery } from "../graphql/generated";

enum FavoriteTypeId {
  Favorite = 1,
  Dislike = -1,
}

interface UseFavorites {
  /**
   * @description If true, the favorites will not be updated in local state until false
   */
  sticky?: boolean;
}

type Favorite = {
  recipeId: number;
  mainRecipeId: number | null;
  typeId: number;
};

type Dislike = {
  recipeId: number;
  mainRecipeId: number | null;
  typeId: number;
};

type RecipeLike = {
  recipeId: number;
  mainRecipeId: number | null;
  recipeName: string;
};

const _isFavorited = (f: Favorite, id: number) => {
  return (
    (f.mainRecipeId === id || f.recipeId === id) &&
    f.typeId === FavoriteTypeId.Favorite
  );
};

const _isDisliked = (f: Dislike, id: number) => {
  return (
    (f.mainRecipeId === id || f.recipeId === id) &&
    f.typeId === FavoriteTypeId.Dislike
  );
};

export const useFavorites = ({ sticky }: UseFavorites = {}) => {
  const { data: meQuery } = useMeQuery();

  const track = useTrack();

  const [handleFavorite] = useFavoriteRecipeMutation();
  const [handleUnfavorite] = useUnfavoriteRecipeMutation();

  const enableFavorites = !!meQuery;

  const { data: recipesAgreementFavoritesQuery } =
    useRecipesAgreementFavoritesQuery(
      {
        pageNumber: 1,
        resultsPerPage: 10_000,
      },
      { skip: !enableFavorites },
    );

  const reactions =
    recipesAgreementFavoritesQuery?.recipesAgreementFavorites.recipeFavorites;

  const [favorites, setFavorites] = useState<Favorite[]>([]);

  const [dislikes, setDislikes] = useState<Dislike[]>([]);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!sticky) {
      setFavorites(
        reactions?.filter((f) => f.typeId === FavoriteTypeId.Favorite) || [],
      );

      setDislikes(
        reactions?.filter((f) => f.typeId === FavoriteTypeId.Dislike) || [],
      );

      setIsReady(!!reactions);
    }
  }, [sticky, reactions, setFavorites]);

  const toggle = <T extends FavoriteTypeId>(typeId: T, recipe: RecipeLike) => {
    const isToggled = reactions?.some(
      (f) =>
        (f.mainRecipeId === recipe.mainRecipeId ||
          f.recipeId === recipe.recipeId) &&
        f.typeId === typeId,
    );

    if (!isToggled) {
      handleFavorite({
        recipeId: recipe.recipeId,
        mainRecipeId: recipe.mainRecipeId,
        recipeFavoriteTypeId: typeId,
      });

      if (typeId === FavoriteTypeId.Favorite) {
        track("recipeFavorited", {
          affiliation: "Frontend process",
          recipeId: recipe.recipeId,
          recipeName: recipe.recipeName,
          mainRecipeId: recipe.mainRecipeId,
          type: "favorite",
        });
      }

      if (typeId === FavoriteTypeId.Dislike) {
        track("recipeFavorited", {
          affiliation: "Frontend process",
          recipeId: recipe.recipeId,
          recipeName: recipe.recipeName,
          mainRecipeId: recipe.mainRecipeId,
          type: "dislike",
        });
      }
    } else {
      handleUnfavorite({
        recipeId: recipe.recipeId,
        mainRecipeId: recipe.mainRecipeId,
        recipeFavoriteTypeId: typeId,
      });

      if (typeId === FavoriteTypeId.Favorite) {
        track("recipeUnfavorited", {
          affiliation: "Frontend process",
          recipeId: recipe.recipeId,
          recipeName: recipe.recipeName,
          mainRecipeId: recipe.mainRecipeId,
          type: "favorite",
        });
      }

      if (typeId === FavoriteTypeId.Dislike) {
        track("recipeUnfavorited", {
          affiliation: "Frontend process",
          recipeId: recipe.recipeId,
          recipeName: recipe.recipeName,
          mainRecipeId: recipe.mainRecipeId,
          type: "dislike",
        });
      }
    }
  };

  const toggleFavorite = (recipe: RecipeLike) =>
    toggle(FavoriteTypeId.Favorite, recipe);

  const toggleDislike = (recipe: RecipeLike) =>
    toggle(FavoriteTypeId.Dislike, recipe);

  const isFavorited = (recipe: RecipeLike, bypass?: boolean) => {
    const id = recipe.mainRecipeId || recipe.recipeId;

    // If bypass is true, we check the server state instead of the local state
    if (bypass) {
      return !!reactions?.some((f) => _isFavorited(f, id));
    }

    return favorites.some((f) => _isFavorited(f, id));
  };

  const isDisliked = (recipe: RecipeLike, bypass?: boolean) => {
    const id = recipe.mainRecipeId || recipe.recipeId;

    // If bypass is true, we check the server state instead of the local state
    if (bypass) {
      return !!reactions?.some((d) => _isDisliked(d, id));
    }

    return dislikes.some((d) => _isDisliked(d, id));
  };

  return {
    favorites,
    dislikes,
    isFavorited,
    isDisliked,
    enableFavorites,
    toggleFavorite,
    toggleDislike,
    isReady,
  };
};
