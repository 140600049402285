import { useEffect, useState } from "react";
import clsx from "clsx";

import { TAXONOMY_IDS } from "@chef/constants";
import { removeDuplicates } from "@chef/utils/removeDuplicates";
import { getPortionsValueAsStringWithPlus } from "@chef/state-management/helpers";
import { WakeLockToggle } from "@chef/smart-components";

import { IRecipeComponentProps } from "../RecipeModal";
import { formatPortionsIngredientSections } from "../../utils/recipe";
import { AllergensBox } from "./AllergensBox";
import { IngredientSections } from "./IngredientSections";
import { ChefTipBox } from "./ChefTipBox";
import { PortionsSelector } from "./PortionsSelector";
import { RecipeDescription } from "./RecipeDescription";
import { RecipeStepSections } from "./RecipeStepSections";
import { NutritionalContentBox } from "./NutritionalContentBox";
import { RichSnippet } from "./RichSnippet";
import { BeverageSuggestion } from "./BeverageSuggestion";
import { BasicGoodsBox } from "./BasicGoodsBox";

import { intl } from "./Recipe.Intl";

enum MEASUREMENET_TYPE {
  PORTION = "portion",
  GRAM = "gram",
}

// We will be using this when we have the correct data for the nutrition per gram
// const RecipeNutritionOptions: PropsOf<
//   typeof RadioButtonBar<MEASUREMENET_TYPE>
// >["options"] = [
//   {
//     name: intl.NUTRITION_PER_PORTION,
//     value: MEASUREMENET_TYPE.PORTION,
//   },
//   {
//     name: intl.NUTRITION_PER_GRAM,
//     value: MEASUREMENET_TYPE.GRAM,
//   },
// ];

export const Recipe = ({
  recipe,
  week,
  year,
  disableUpdateBasket = false,
  isInBasket = false,
  addOrRemoveToBasket,
  productVariation,
  overridePortionSize,
  overrideDisableDropdown,
  plusProteinVariation,
  isPlusProteinInBasket = false,
  showCTA,
  signup,
}: IRecipeComponentProps) => {
  const portionSizes = recipe?.instructions?.portions
    .map((portion) => portion.size)
    .sort();

  let selectedPortionSizeValue =
    productVariation &&
    getPortionsValueAsStringWithPlus(
      isPlusProteinInBasket && plusProteinVariation
        ? plusProteinVariation
        : productVariation,
    );

  if (overridePortionSize) {
    selectedPortionSizeValue = overridePortionSize;
  }

  const initialLoadIndex = portionSizes?.findIndex(
    (p) => p === selectedPortionSizeValue,
  );

  const [selectedPortionSizeIndex, setSelectedPortionSizeIndex] = useState(
    !initialLoadIndex || initialLoadIndex === -1 ? 0 : initialLoadIndex,
  );

  // const [selectedNutritionSection, setSelectedNutritionSection] =
  //   useState<MEASUREMENET_TYPE>(MEASUREMENET_TYPE.PORTION);

  // this gets triggered if user adds plus portions
  useEffect(() => {
    const newIndex = portionSizes?.findIndex(
      (p) => p === selectedPortionSizeValue,
    );
    if (newIndex === -1) {
      return;
    }

    setSelectedPortionSizeIndex(newIndex);
  }, [selectedPortionSizeValue]);

  if (!recipe) {
    return <div>No recipe</div>;
  }

  const portions = formatPortionsIngredientSections(
    recipe?.instructions?.portions,
  );

  const basicGoods =
    (portions[selectedPortionSizeIndex]?.ingredientSections.find(
      // Not apparent to me how to solve this with TS without rewriting a lot of code. `isBasisArray` is added in `formatPortionsIngredientSections` from `../utils/recipe.ts`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (s) => s.isBasisArray,
    )?.ingredients as string[]) || [];

  const hasChefPartnerScore = recipe.taxonomies.some(
    (t) => t.taxonomyId === TAXONOMY_IDS.WEIGHT_WATCHERS,
  );

  return (
    <div className="grid items-start grid-cols-4 gap-6 lg:grid-cols-10">
      <RichSnippet recipe={recipe} />

      <div className="bg-white rounded-lg col-span-full">
        <RecipeDescription
          recipe={recipe}
          week={week}
          year={year}
          selectedPortionSize={portions[selectedPortionSizeIndex]}
          disableUpdateBasket={disableUpdateBasket}
          isInBasket={isInBasket}
          addOrRemoveToBasket={addOrRemoveToBasket}
          productVariation={productVariation}
          plusProteinVariation={plusProteinVariation}
          isPlusProteinInBasket={isPlusProteinInBasket}
          showCTA={showCTA}
          signup={signup}
        />
      </div>

      <div className="col-span-4 p-4 bg-white rounded-lg lg:p-10">
        <PortionsSelector
          portionSizes={portionSizes}
          selectedPortionSizeIndex={selectedPortionSizeIndex}
          setSelectedPortionSizeIndex={setSelectedPortionSizeIndex}
          disableSelectPortions={
            overrideDisableDropdown || (!!productVariation && !signup)
          }
        />

        <div className="flex flex-col items-center justify-center w-full mt-8 mb-6">
          <AllergensBox
            allergens={portions[selectedPortionSizeIndex]?.allergies}
          />

          <BasicGoodsBox basicGoods={removeDuplicates(basicGoods)} />
        </div>

        <div className="lg:mb-3">
          <IngredientSections
            ingredientSections={
              portions[selectedPortionSizeIndex]?.ingredientSections as any // `formatPortionsIngredientSections` is changing the type
            }
          />
        </div>

        {/* We will show the nutrition per gram information when we have the correct data*/}
        {/*<RadioButtonBar
            options={RecipeNutritionOptions}
            value={selectedNutritionSection}
            onChange={(val) => {
              setSelectedNutritionSection(val);
            }}
            small
            className="mb-4 mt-14"
          />*/}
        <div
          className={clsx(
            "flex flex-col items-center justify-center w-full pt-3 mt-3 lg:my-3",
            hasChefPartnerScore && "hidden",
          )}
        >
          <NutritionalContentBox
            nutritionalContent={
              portions[selectedPortionSizeIndex]?.nutritionFacts
            }
            type={MEASUREMENET_TYPE.PORTION}
          />
        </div>

        <div className="mt-6">
          <h6 className="mb-1">
            <strong>{intl.ALLERGY_HEADER}</strong>
          </h6>

          <p>{intl.ALLERGY_TEXT}</p>
        </div>
      </div>
      <div className="col-span-4 p-4 bg-white rounded-lg lg:col-span-6 lg:p-10">
        <div className="flex items-center justify-between pb-2 border-b border-grey-1">
          <h2>
            <strong>{intl.RECIPE_STEP_SECTION_HEADER}</strong>
          </h2>
        </div>
        <WakeLockToggle className="bg-white" />

        {recipe.chefTip && (
          <ChefTipBox content={recipe.chefTip} className="mt-2 mb-12" />
        )}
        <RecipeStepSections
          stepSections={portions[selectedPortionSizeIndex]?.stepSections}
          enableTrackingSteps
        />

        <BeverageSuggestion
          beverageSuggestion={recipe.beverageSuggestion}
          className="mt-8"
        />
      </div>
    </div>
  );
};
