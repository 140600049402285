import { FILTER_CATEGORIES, TAXONOMY_TYPES } from "@chef/constants";
import { slugify } from "@chef/helpers";
import { isEmptyArray } from "@chef/utils/array";

import {
  PickAndMixQuery,
  RecipesByProductIdListQuery,
} from "../graphql/generated";

export const allowedFilterCategoryTaxonomies = [
  TAXONOMY_TYPES.CATEGORY,
  TAXONOMY_TYPES.CAMPAIGN,
  TAXONOMY_TYPES.SPECIALFOOD,
] as string[];

type PickAndMixRecipe =
  | PickAndMixQuery["pickAndMix"][number]["recipes"][number]
  | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"][number];

type PickAndMixRecipeWithPrice = PickAndMixRecipe & {
  price?: number;
  productId?: never;
};

type PickAndMixRecipeWithPriceAndProductId = PickAndMixRecipe & {
  price?: number;
  productId: string;
};

type Recipe = PickAndMixRecipeWithPrice | PickAndMixRecipeWithPriceAndProductId;

interface IFilterCategory {
  value: string;
  label: string;
  count: number;
  type: "default" | "highlighted" | "secondary";
  isAll: boolean;
  description?: string;
}

class FilterCategories {
  public categories: Map<string, FilterCategory>;

  private recommendations: Set<string>;
  private favorites: Set<number>;

  private implicits: Record<
    "all" | "favorites" | "recommendations",
    FilterCategory
  >;

  constructor(
    recommendations: Set<string> | null,
    favorites: { recipeId: number; mainRecipeId: number | null }[] | null,
  ) {
    this.implicits = {
      all: new FilterCategory(
        FILTER_CATEGORIES.ALL_CATEGORIES_SLUG,
        FILTER_CATEGORIES.ALL_CATEGORIES,
        "default",
      ),
      favorites: new FilterCategory(
        FILTER_CATEGORIES.FAVORITES_SLUG,
        FILTER_CATEGORIES.FAVORITES,
        "secondary",
      ),
      recommendations: new FilterCategory(
        FILTER_CATEGORIES.RECOMMENDATIONS_SLUG,
        FILTER_CATEGORIES.RECOMMENDATIONS,
        "secondary",
      ),
    };

    this.categories = new Map([
      [FILTER_CATEGORIES.ALL_CATEGORIES_SLUG, this.implicits.all],
      [FILTER_CATEGORIES.RECOMMENDATIONS_SLUG, this.implicits.recommendations],
      [FILTER_CATEGORIES.FAVORITES_SLUG, this.implicits.favorites],
    ]);

    this.recommendations = new Set([...(recommendations || [])]);

    this.favorites = new Set(
      favorites?.map((f) => f.mainRecipeId ?? f.recipeId) || [],
    );
  }

  has(slug: string) {
    return this.categories.has(slug);
  }

  get(slug: string): FilterCategory | undefined {
    return this.categories.get(slug);
  }

  create(
    slug: string,
    label: string,
    type: "default" | "highlighted" | "secondary",
    description?: string,
  ) {
    this.categories.set(
      slug,
      new FilterCategory(slug, label, type, description),
    );
  }

  insertRecipe(recipe: Recipe) {
    const taxonomies = recipe.taxonomies.filter((t) =>
      allowedFilterCategoryTaxonomies.includes(t.type),
    );
    for (const tax of taxonomies) {
      const slugifiedName = slugify(tax.name);

      if (!this.has(slugifiedName)) {
        this.create(
          slugifiedName,
          tax.name,
          tax.type === TAXONOMY_TYPES.CAMPAIGN ? "highlighted" : "default",
          tax.description,
        );
      }

      const c = this.get(slugifiedName);

      if (c) {
        c.addRecipe(recipe);

        this.implicits.all.addRecipe(recipe);

        if (
          this.favorites.has(recipe.recipeId) ||
          this.favorites.has(recipe.mainRecipeId)
        ) {
          this.implicits.favorites.addRecipe(recipe);
        }

        if (this.recommendations.has(recipe.productId || "")) {
          this.implicits.recommendations.addRecipe(recipe);
        }
      }
    }
  }

  toArray(
    args: {
      exclude?: {
        all?: boolean;
        favorites?: boolean;
        recommendations?: boolean;
      };
    } = {},
  ): IFilterCategory[] {
    let values = [...this.categories.values()];

    const { exclude = {} } = args;

    if (exclude.all) {
      values = values.filter(
        (v) => v.slug !== FILTER_CATEGORIES.ALL_CATEGORIES_SLUG,
      );
    }

    if (exclude.favorites) {
      values = values.filter(
        (v) => v.slug !== FILTER_CATEGORIES.FAVORITES_SLUG,
      );
    }

    if (exclude.recommendations) {
      values = values.filter(
        (v) => v.slug !== FILTER_CATEGORIES.RECOMMENDATIONS_SLUG,
      );
    }

    return values.map((v) => v.output);
  }
}

class FilterCategory {
  public slug: string;
  public label: string;
  public type: "default" | "highlighted" | "secondary";
  public description?: string;
  public isAll: boolean;

  private recipes: Set<number>;

  constructor(
    slug: string,
    label: string,
    type: "default" | "highlighted" | "secondary",
    description?: string,
  ) {
    this.recipes = new Set();

    this.slug = slug;
    this.label = label;
    this.type = type;
    this.description = description;

    this.isAll = slug === FILTER_CATEGORIES.ALL_CATEGORIES_SLUG;
  }

  get count() {
    return this.recipes.size;
  }

  get output(): IFilterCategory {
    return {
      value: this.slug,
      label: this.label,
      count: this.count,
      type: this.type,
      isAll: this.isAll,
      description: this.description,
    };
  }

  addRecipe(recipe: Recipe) {
    this.recipes.add(recipe.recipeId);
  }
}

const generateTerms = (recipe: Recipe) => {
  return recipe.taxonomies
    .filter((t) => allowedFilterCategoryTaxonomies.includes(t.type))
    .map((t) => slugify(t.name))
    .join(" ");
};

const getFilterCategoriesFromRecipes = (
  recipes:
    | PickAndMixRecipeWithPrice[]
    | PickAndMixRecipeWithPriceAndProductId[],
  recommendations: Set<string> | null,
  favorites: { recipeId: number; mainRecipeId: number | null }[] | null,
  excludeAll?: boolean,
) => {
  const categories = new FilterCategories(recommendations, favorites);

  for (const recipe of recipes) {
    categories.insertRecipe(recipe);
  }

  return categories.toArray({
    exclude: {
      all: excludeAll,
      favorites: !favorites,
      recommendations: !recommendations || isEmptyArray(recommendations),
    },
  });
};

export const generateFilterCategoriesFromRecipes = (args: {
  recipes:
    | PickAndMixRecipeWithPrice[]
    | PickAndMixRecipeWithPriceAndProductId[]
    | PickAndMixRecipe[];
  currentFilter: string;
  recommendations: Set<string> | null;
  favorites: { recipeId: number; mainRecipeId: number | null }[] | null;
  excludeAll?: true;
  myPicks?: { amount: number };
}) => {
  const { recipes, excludeAll, recommendations, favorites } = args;

  const filterCategories = getFilterCategoriesFromRecipes(
    recipes,
    recommendations,
    favorites,
    excludeAll,
  );

  // sort in order of "All", highlighted, secondary, default
  filterCategories.sort((a, b) => {
    if (
      a.value === FILTER_CATEGORIES.ALL_CATEGORIES_SLUG &&
      b.value !== FILTER_CATEGORIES.ALL_CATEGORIES_SLUG
    ) {
      return -1;
    }

    if (
      b.value === FILTER_CATEGORIES.ALL_CATEGORIES_SLUG &&
      a.value !== FILTER_CATEGORIES.ALL_CATEGORIES_SLUG
    ) {
      return 1;
    }

    if (a.type === "highlighted" && b.type !== "highlighted") {
      return -1;
    }

    if (b.type === "highlighted" && a.type !== "highlighted") {
      return 1;
    }

    if (a.type === "secondary" && b.type !== "secondary") {
      return -1;
    }

    if (b.type === "secondary" && a.type !== "secondary") {
      return 1;
    }

    return a.label.localeCompare(b.label);
  });

  return filterCategories;
};

interface IShouldShowRecipeByFilterBase {
  recipe:
    | PickAndMixQuery["pickAndMix"][number]["recipes"][number]
    | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"][number];
  filter: string;
  searchValue?: string;

  recommendations?: never;
  favorites: { recipeId: number; mainRecipeId: number | null }[] | null;
  productId?: never;

  price: number;
}

interface IShouldShowRecipeByFilterWithProductId {
  recipe:
    | PickAndMixQuery["pickAndMix"][number]["recipes"][number]
    | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"][number];
  filter: string;
  searchValue?: string;

  recommendations: Set<string> | null;
  favorites: { recipeId: number; mainRecipeId: number | null }[] | null;
  productId: string;

  price: number;
}

export const shouldShowRecipeByFilter = (
  args: IShouldShowRecipeByFilterBase | IShouldShowRecipeByFilterWithProductId,
) => {
  const { recipe, filter, searchValue, recommendations, productId, favorites } =
    args;

  if (filter === FILTER_CATEGORIES.ALL_CATEGORIES_SLUG) {
    return true;
  }

  if (filter === FILTER_CATEGORIES.RECOMMENDATIONS_SLUG) {
    return recommendations?.has(productId.toLowerCase());
  }

  if (filter === FILTER_CATEGORIES.FAVORITES_SLUG) {
    return favorites?.some(
      (f) =>
        f.mainRecipeId === recipe.mainRecipeId ||
        f.recipeId === recipe.recipeId,
    );
  }

  const terms = generateTerms(recipe);

  if (searchValue) {
    // Show recipe if the id, name or taxonomies match
    const search = searchValue.toLowerCase();

    return (
      recipe.recipeId.toString().includes(search) ||
      recipe.recipeName.toLowerCase().includes(search) ||
      terms.includes(search)
    );
  }

  // Check if the filter is in the terms
  if (terms.includes(filter)) {
    return true;
  }

  return false;
};
